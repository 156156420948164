import React, { Component } from 'react';

class PaginaNaoExiste extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <p>Você não está autorizado a acessar essa pagina</p>
            </>
        )
    }
}
export default PaginaNaoExiste;