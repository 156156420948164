import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import IconeHay from "../images/logo-loading.png";
import { Link } from 'react-router-dom';
import utilService from '../service/UtilService';
import { toast } from 'react-toastify';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                
                <ul id="progress-bar" className="entry-bookend-progress-container header fundo-inicial" >
                    <img alt="Logo da Hay Systems" src={ this.props.operacaoTotem?.logoPrincipalAutoAtendimento || IconeHay }/>
                    <p className="boas-vindas" style={utilService.reactShow(this.props.operacaoTotem?.id)}> {this.props.operacaoTotem?.mensagemBoasVindasAutoAtendimento || "Bem-vindo ao sistema de autoatendimento."}</p>
                    <p className="boas-vindas" style={utilService.reactShow(!this.props.operacaoTotem?.id)}>Acesso não autorizado.</p>
                    <p style={{height: 100}}> </p>
                    <Link to={"/identificacao/" + this.props.operacaoTotem.id} style={utilService.reactShow(this.props.operacaoTotem?.id)} className="botao-autoatendimento primario"  onClick={()=> this.props.contrato?.contratoid && utilService.iniciarTimerSessao(this.props.aoExpirar, toast)}>
                        Toque para começar
                    </Link>
                </ul>
            </>
        )
    }
}
export default Home;
