import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ApiService from '../service/ApiService';
import Modal from '../components/ui/modal';
import { withRouter } from '../ganchos/withRouter';
import {QRCodeCanvas} from 'qrcode.react';
import moment from "moment";
import 'moment/locale/pt-br';
import IconeHay from "../images/logo-loading.png";
import lodash from "lodash";

import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import utilService from '../service/UtilService';

class ContratoSelecionado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pixVisivel: false,
            pixQrCode: 'QRcode Gerado Inválido.',
            identificador: 0,
            cartaoVisivel: false
        }
    }

    async selecionarFatura(fatura){
        this.props.onSuccess && this.props.onSuccess({fatura});
    }

    async cancelarTransacao() {
        const retornoTransacao = await ApiService.cancelarTransacao({
            identificador: this.state.identificador,
            idclientecontrato: this.props.contrato.contratoid,
            idOperacaoTotem: this.props.operacaoTotem.id,
        });
        if (retornoTransacao?.id){
            switch(retornoTransacao.tipoabortagem){
                case "main.clientetransacao.tipoabortagem.naopodeserabortado":
                    toast.warning("O comando não pode ser abortado. Consulte novamente a sua fatura para saber se o procedimento foi concluido com sucesso.", {
                        theme: "colored"
                    });
                    break;
                default:
                    toast.success("Transacao cancelada com sucesso.", {
                        theme: "colored"
                    });
                    break;
            }
        } else {
            toast.error("Falha ao Processar Cancelamento. Contate o Administrador de Sistemas.",{
                autoClose: false,
                position: "top-center",
                theme: "colored",
                closeOnClick: true,
            });
        }
    }

    formatarNome() {
        return lodash.truncate(this.props.contrato?.nomeassinante || "Fulano da Silva", { length: 35 });
    }

    formatarCpf() {
        return utilService.formatCpfCnpj(this.props.contrato?.cpfCnpj || "11122233344");
    }

    async aoExpirar(){
        if (this.state.identificador){
            await this.cancelarTransacao();
        }
        window.location.replace("/?idOperacaoTotem=" + this.props.operacaoTotem.id);
    }

    async pagarCartaoDebito(fatura){
        utilService.iniciarTimerSessao(() => this.aoExpirar(), toast);
        const identificador = moment().valueOf();
        this.setState({ identificador });
        const retornoTransacao = await ApiService.iniciarTransacao({
            idclientecontrato: this.props.contrato.contratoid,
            idOperacaoTotem: this.props.operacaoTotem.id,
            tipoTransacao: 'main.formapagamento.debito',
            valor: fatura.valordocumento,
            idfatura: fatura.idfatura,
            identificador,
            vencimento: fatura.datavencimentoraw
        }, () => this.cancelarTransacao() );
        this.setState({ identificador: '' })
        if (retornoTransacao?.status){   
            toast.success("Documento Processado com sucesso", {
                theme: "colored"
            });
            const faturas = await ApiService.buscarFaturasPorContrato({ idcontrato: this.props.contrato.contratoid }) || [];
            if (faturas.length){
                this.props.onSuccess && this.props.onSuccess({ ...this.props.contrato, ...faturas });
            } else {
                return this.props.navigate("/menuinicial/"+ this.props.operacaoTotem.id);
            }
        } else {            
            toast.error("Falha ao Processar Pagamento. Caso o problema persista, contate o Administrador de Sistemas. Detalhes: ["+ retornoTransacao?.ultimamensagem +"]",{
                autoClose: false,
                position: "top-center",
                theme: "colored",
                closeOnClick: true,
            });
        }
    }

    async pagarCartaoCredito(fatura){
        utilService.iniciarTimerSessao(() => this.aoExpirar(), toast);
        const identificador = moment().valueOf();
        this.setState({ identificador });
        const retornoTransacao = await ApiService.iniciarTransacao({
            idclientecontrato: this.props.contrato.contratoid,
            idOperacaoTotem: this.props.operacaoTotem.id,
            tipoTransacao: 'main.formapagamento.credito',
            valor: fatura.valordocumento,
            idfatura: fatura.idfatura,
            identificador,
            vencimento: fatura.datavencimentoraw
        }, () => this.cancelarTransacao());
        this.setState({ identificador: '' })
        if (retornoTransacao?.status){
            toast.success("Documento Processado com sucesso");
            const faturas = await ApiService.buscarFaturasPorContrato({ idcontrato: this.props.contrato.contratoid }) || [];
            if (faturas.length){
                this.props.onSuccess && this.props.onSuccess({ ...this.props.contrato, ...faturas });
            } else {
                return this.props.navigate("/menuinicial/"+ this.props.operacaoTotem.id);
            }
        } else {
            toast.error("Falha ao Processar Pagamento. Caso o problema persista, contate o Administrador de Sistemas. Detalhes: ["+ retornoTransacao?.ultimamensagem +"]",{
                autoClose: false, 
                position: "top-center",
                theme: "colored",
                closeOnClick: true,
            });
        }
    }

    async pagarPix(fatura){
        const identificador = moment().valueOf();
        this.setState({ identificador });
        const retornoTransacao = await ApiService.iniciarTransacao({
            idclientecontrato: this.props.contrato.contratoid,
            idOperacaoTotem: this.props.operacaoTotem.id,
            tipoTransacao: 'main.formapagamento.pix',
            valor: fatura.valordocumento,
            idfatura: fatura.idfatura,
            identificador
        }, () => this.cancelarTransacao());
        if (retornoTransacao?.status && retornoTransacao?.retorno?.detalhe?.pixPagamento){
            return this.setState({
                pixVisivel: true,
                pixQrCode: retornoTransacao?.retorno?.detalhe?.pixPagamento
            });
        }
        toast.error("Falha ao Processar Pagamento. Caso o problema persista, contate o Administrador de Sistemas. Detalhes: ["+ retornoTransacao?.ultimamensagem +"]",{
            autoClose: false,
                position: "bottom-center",
                theme: "colored",
                closeOnClick: true,
        });
    }

    async exibirPix(fatura){
        return this.setState({
            pixVisivel: true,
            pixQrCode: fatura.qrcode
        });
    }

    async exibirCartao(fatura){
        return this.setState({
            cartaoVisivel: true,
            fatura: fatura
        });
    }

    async detalhe(fatura){
        window.open(fatura.urlpdf, "_blank");
    }

    async imprimirSegundaVia(fatura){
        //console.log(fatura);
        if (fatura.urlpdf){
            const retornoTransacao = await ApiService.imprimirSegundaVia({
                idOperacaoTotem: this.props.operacaoTotem.id,
                url: fatura.urlpdf,
                linhadigitavel: fatura.linhadigitavel
            });
            if (retornoTransacao.state == "main.mensagem.impressora.operacional"){
                toast.success("Documento Impresso com sucesso");
            } else {
                toast.error("Falha ao Imprimir a Segunda Via. Caso o problema persista, contate o Administrador de Sistemas.",{
                    autoClose: false, 
                    position: "top-center",
                    theme: "colored",
                    closeOnClick: true,
                });
            }
        } else {
            alert("Este documento não possui segunda-via disponível. Entre em contato com a administração");
        }
    }

    formatarAno(fatura){
        return moment(fatura.datavencimento, "YYYY-MM-DD").format("YYYY");
    }

    formatarMes(fatura){
        return lodash.capitalize(moment(fatura.datavencimento, "YYYY-MM-DD").locale('pt-BR').format("MMMM"));
    }
    formatarValor(fatura){
        var formatBRL= Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        })
        return formatBRL.format(fatura.valordocumento);
    }

    render() {
        return ( 
            <>
                <ul id="progress-bar" className="entry-bookend-progress-container header fundo-contratos">
                    <div className="topo-navegacao">
                        <img alt="Logo da Hay Systems" src={this.props.operacaoTotem.logoPrincipalAutoAtendimento || IconeHay} className={"img-topo-navegacao"} />
                        <p className="nome-usuario-navegacao">{this.formatarNome()}</p>
                        <p className="cpf-usuario-navegacao">{this.formatarCpf()} </p>
                    </div>
                    <ul className="entry-bookend-progress-container header breadcrumb" >
                        <li className="progress-item progress-step" >
                            <span className="circle progress-index numero numero-breadcrumb" >
                                <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                    <FontAwesomeIcon className={"fa-fw index unselectable circulo-breadcrumb"} size="3x" icon={"circle"} />
                                    <span class="fa-layers-text fa-1x unselectable" style={{ fontWeight: 900, color: "white" }}> 1 </span>
                                </span>
                            </span>
                        </li>
                        <p className="texto-breadcrumb">Identificacao</p>
                        <FontAwesomeIcon className='fa-fw icone-resultado chevron-breadcrumb' size="s"  icon={"chevron-right"} />
                        <li className="progress-item progress-step" >
                            <span className="circle progress-index numero numero-breadcrumb" >
                                <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                    <FontAwesomeIcon className={"fa-fw index unselectable circulo-breadcrumb"} size="3x" icon={"circle"} />
                                    <span class="fa-layers-text fa-1x unselectable" style={{ fontWeight: 900, color: "white"  }}> 2 </span>
                                </span>
                            </span>
                        </li>
                        <p className="texto-breadcrumb">Contrato</p>
                        <FontAwesomeIcon className='fa-fw icone-resultado chevron-breadcrumb' size="s"  icon={"chevron-right"} />
                        <li className="progress-item progress-step">
                            <span className="circle progress-index numero numero-breadcrumb" >
                                <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                    <FontAwesomeIcon className={"fa-fw index unselectable circulo-breadcrumb"} size="3x" icon={"circle"} />
                                    <span class="fa-layers-text fa-1x unselectable" style={{ fontWeight: 900, color: "white"  }}> 3 </span>
                                </span>
                            </span>
                        </li>
                        <p className="texto-breadcrumb">Faturas</p>
                        
                        <Link to={"/selecionarcontrato/" + this.props.operacaoTotem.id} className="voltar-etapa" >
                            Voltar uma etapa
                        </Link>
                        <p className="texto-breadcrumb">Inicio</p>
                        
                        <li className="progress-item progress-step" >
                            <span className="circle progress-index numero numero-breadcrumb" >
                                <Link to={"/menuinicial/" + this.props.operacaoTotem.id}  >
                                    
                                        <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                            <FontAwesomeIcon className={"fa-fw index unselectable circulo-breadcrumb"} size="3x" icon={"circle"} style={{ color: "lime" }} />
                                            <FontAwesomeIcon className={"fa-fw index unselectable"} size="" icon={"home"} />
                                        </span>
                                </Link>
                            </span>
                        </li>
                    </ul>

                    <span className="entry-bookend-progress-container header" style={{
                        paddingBottom: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        width: "calc(100% - 190px)",
                        fontSize: "xx-large",
                        fontWeight: 900,
                        position: 'fixed',
                        bottom: 0
                        
                    }}>

                        <p className="selecao-contrato"> 3. Faturas: </p>

                        <ul id="progress-bar" className="entry-bookend-progress-container header lista-faturas" style={{height: 420, overflowY: "auto", alignItems:'flex-start'}}>
                            
                            {/* <span className="entry-bookend-progress-container header interior" >
                                <div style={{width: "90%"}}>
                                    <p className="nome-cliente">Heitor Ribeiro Trevisan </p>
                                    <p className="endereco-cliente">Rua Doutor João Antiqueira Elias 07 </p>
                                    <p className="endereco-cliente">Jardim Universitário - Marília</p>
                                    <p className=""></p>
                                    <p className="labels">
                                        <span className="label-contrato">Contrato: 160504</span>
                                        <span className="label-situacao">Atrasada</span>
                                    </p>
                                    
                                </div>
                                <a className="botao-contrato">
                                    <FontAwesomeIcon className='fa-fw icone-resultado' size="lg"  icon={"chevron-right"} />
                                </a>

                            </span> */}
                            {
                                this.props.contrato?.faturasPendentes?.filter((contrato) => {
                                    return true
                                }).map(fatura => {
                                    return (
                                        <>
                                            <span className="entry-bookend-progress-container header interior" >
                                                <div className='subdivisao-faturas primeira-metade'>
                                                    <div style={{width: "50%", textAlign: 'left', paddingLeft: 20}}>
                                                        <span className='ano-mes'>
                                                            <p className="ano-fatura">{this.formatarAno(fatura)}</p>
                                                            <p className="mes-fatura">{this.formatarMes(fatura)}</p>
                                                        </span>
                                                        <span className="label-numerodocumento">Documento: {fatura?.numerodocumento}</span>
                                                    </div>
                                                    <div style={{textAlign: 'right', width: "45%"}}>
                                                        <p className="valor-fatura">{this.formatarValor(fatura)}</p>
                                                        <span className="label-situacao-fatura">{fatura?.situacaofatura}</span>
                                                    </div>
                                                </div>
                                                <div className='subdivisao-faturas' style={{ marginBottom: 30 }}>
                                                    <div style={{width: "35%", textAlign: 'left', paddingLeft: 20}}>
                                                        <span className="ano-fatura">Vencimento: {fatura?.numerodocumento}</span>
                                                    </div>
                                                    <div style={{textAlign: 'right', flexGrow: 1,paddingRight: 10 }}>
                                                        <a className="botao-cartao botao-autoatendimento secundario" style={{marginRight: 10}}  onClick={()=> this.imprimirSegundaVia(fatura) }>
                                                            <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"print"}   />  Imprimir
                                                        </a>
                                                        <a className="botao-cartao botao-autoatendimento primario"  onClick={()=> this.exibirCartao(fatura) }>
                                                            <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"credit-card"}  /> Pagar com Cartão
                                                        </a>
                                                        <a className="botao-pix botao-autoatendimento secundario"  onClick={()=> this.exibirPix(fatura) }>
                                                            <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"qrcode"}  />Pagar com PIX
                                                        </a>
                                                    </div>
                                                </div>
                                            </span>
                                        </>
                                    )
                                })
                            }
                            {
                                (this.props.contrato?.faturasPendentes?.length == 0) && 
                                    <>
                                        <p style={{fontSize: 'x-large'}}>Seu contrato não possui faturas pendentes.</p>
                                    </>
                            }
                        </ul>
                    </span>
                </ul>
                <Modal visible={this.state.cartaoVisivel} icon={"credit-card"} title={"Pagar com Cartão"} onClose={() => this.setState({ cartaoVisivel: false }) }>
                    <p style={{fontSize: 22 }}>Como deseja prosseguir?</p>
                    <a className="botao-cartao botao-autoatendimento primario"  onClick={()=> this.pagarCartaoCredito(this.state.fatura)} style={{marginTop: 20}} >
                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"credit-card"}  /> Pagar com Cartão de Crédito
                    </a>
                    <a className="botao-pix botao-autoatendimento secundario"  onClick={()=> this.pagarCartaoDebito(this.state.fatura)} style={{marginBottom: 20, marginTop: 20 }}>
                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"credit-card"}  />Pagar com Cartão de Débito
                    </a>
                </Modal>
                <Modal visible={this.state.pixVisivel} icon={"qrcode"} title={"Pix para Pagamento"} onClose={() => this.setState({ pixVisivel: false }) }>
                    Realize o pagamento no seu internet banking, fazendo a leitura do QRCODE abaixo. Em até 15 minutos, seu pagamentos será reconhecido e o seu contrato re-estabelecido.
                    <QRCodeCanvas value={this.state.pixQrCode} style={{padding: 10 }} />  
                </Modal>
            </>
        )
    }
}
export default withRouter(ContratoSelecionado);