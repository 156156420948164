import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ApiService from '../service/ApiService';
import { withRouter } from '../ganchos/withRouter';
import { toast } from 'react-toastify';
import IconeHay from "../images/logo-loading.png";
import lodash from "lodash";
import utilService from '../service/UtilService';
import { Link } from 'react-router-dom';
import Modal from '../components/ui/modal';

class MenuInicial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liberacaoConfiancaVisivel: false
        }
    }

    formatarNome() {
        return lodash.truncate(lodash.first(this.props.contratos)?.nomeassinante || "Fulano da Silva", { length: 35 });
    }

    formatarCpf() {
        return utilService.formatCpfCnpj(lodash.first(this.props.contratos)?.cpfCnpj || "11122233344");
    }

    async efetuarLiberacaoEmConfianca() {
        const retorno = await ApiService.liberacaoEmConfianca({
            idcontrato: this.props.contrato.contratoid,
        });
        switch (retorno?.state) {
            case "menu.mensagem.liberacao.em.confianca.realizada":
                toast.success("Documento Processado com sucesso");
            break;
            case "menu.mensagem.liberacao.em.confianca.nao.realizada":
            default:
                toast.error("Ocorreu um erro na Liberação em Confiança. Contate a nossa central de atendimento.",{
                    autoClose: false,
                    position: "bottom-center",
                    theme: "colored",
                    closeOnClick: true,
                });
        }
    }

    render() {
        return (
            <>
                <ul id="progress-bar" className="entry-bookend-progress-container header fundo-contratos">
                    <div className="topo-navegacao">
                        <img alt="Logo da Hay Systems" src={this.props.operacaoTotem.logoPrincipalAutoAtendimento || IconeHay} className={"img-topo-navegacao"} />
                        <p className="nome-usuario-navegacao">{this.formatarNome()}</p>
                        <p className="cpf-usuario-navegacao">{this.formatarCpf()} </p>
                    </div>

                    <span className="entry-bookend-progress-container header" style={{
                        paddingBottom: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        width: "calc(100% - 190px)",
                        fontSize: "xx-large",
                        fontWeight: 900,

                    }}>

                        <p className="selecao-contrato"> Escolha uma ação: </p>

                        <ul id="progress-bar" className="entry-bookend-progress-container header lista-contratos" style={{ maxHeight: 500, flexDirection: 'column', alignContent: 'center' }}>
                            <div className="menu-split">
                                <Link to={"/selecionarcontrato/" + this.props.operacaoTotem.id} className="entry-bookend-progress-container header interior menu" >
                                    <div style={{ flexDirection: 'column' }}>
                                        <p className="menu-inicial"> <FontAwesomeIcon className='fa-fw icone-resultado' color='white' size="2x" icon={"file-lines"} /> Contratos</p>
                                    </div>
                                    
                                    <a className="botao-contrato" >
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"chevron-right"} />
                                    </a>
                                </Link>
                                <Link to={"/atendimento/" + this.props.operacaoTotem.id} className="entry-bookend-progress-container header interior menu" >
                                    <div style={{ flexDirection: 'column' }}>
                                        <p className="menu-inicial"> <FontAwesomeIcon className='fa-fw icone-resultado' color='white' size="2x" icon={"fa-brands fa-whatsapp"} /> Atendimento</p>
                                    </div>
                                    
                                    <a className="botao-contrato" >
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"chevron-right"} />
                                    </a>
                                </Link>
                                <a className="entry-bookend-progress-container header interior menu" onClick={ ()=> this.setState({ liberacaoConfiancaVisivel: true }) } >
                                    <div style={{ flexDirection: 'column' }}>
                                        <p className="menu-inicial" style={{ padding: 32}}> <FontAwesomeIcon className='fa-fw icone-resultado' color='white' size="2x" icon={"fa-handshake-angle"} /> Liberação em Confiança</p>
                                    </div>
                                    
                                    <a className="botao-contrato" >
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"chevron-right"} />
                                    </a>
                                </a>
                            </div>
                            <div className="menu-split">
                                <Link to={"/contratoselecionado/" + this.props.operacaoTotem.id} className="entry-bookend-progress-container header interior menu" >
                                    <div style={{ flexDirection: 'column',  }}>
                                        <p className="menu-inicial"> <FontAwesomeIcon className='fa-fw icone-resultado' color='white' size="2x" icon={"file-invoice-dollar"} /> Faturas</p>
                                    </div>
                                    
                                    <a className="botao-contrato" >
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"chevron-right"} />
                                    </a>
                                </Link>
                                <Link to={"/?idOperacaoTotem=" + this.props.operacaoTotem.id} onClick={()=> this.props.aoExpirar() } className="entry-bookend-progress-container header interior menu" style={{backgroundColor: "#00ADFF"}} >
                                    <div style={{ flexDirection: 'column' }}>
                                        <p className="menu-inicial"> <FontAwesomeIcon className='fa-fw icone-resultado' color='white' size="2x" icon={"fa-arrow-right-from-bracket"} flip="both"  /> Encerrar</p>
                                    </div>
                                </Link>
                                <a className="entry-bookend-progress-container header interior menu" onClick={ ()=> this.setState({ liberacaoConfiancaVisivel: true }) } style={{ visibility: 'hidden' }} >
                                    <div style={{ flexDirection: 'column' }}>
                                        <p className="menu-inicial" style={{ padding: 32}}> <FontAwesomeIcon className='fa-fw icone-resultado' color='white' size="2x" icon={"fa-handshake-angle"} /> Liberação em Confiança</p>
                                    </div>                                    
                                    <a className="botao-contrato" >
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"chevron-right"} />
                                    </a>
                                </a>
                            </div>
                        </ul>
                    </span>
                </ul>

                <Modal visible={this.state.liberacaoConfiancaVisivel} icon={"credit-card"} title={"Pagar com Cartão"} onClose={() => this.setState({ liberacaoConfiancaVisivel: false }) }>
                    <span className="circle progress-index larguinho" style={{marginBottom: 20}}>
                            <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" title={this.props.contrato?.situacao} icon={"user"} />
                            <span className="index elipsis"> Assinante: {this.props.contrato?.nomeassinante} </span>
                            <p className='linha-contrato'>Endereço: {this.props.contrato?.endereco}</p>
                            <p className='linha-contrato'>Situacao: {this.props.contrato?.situacao} IdAssinante: [{this.props.contrato?.idassinante}] Contrato: [{this.props.contrato?.numerocontrato}] </p>
                            <p>Você pode solicitar a liberação em confiança por mais três dias, até que você regularize a sua situação financeira conosco.</p>
                            <p>Caso o pagamento não seja confirmado em 3 dias úteis, o sinal será
                            reduzido.</p> <br/>
                            <a className="botao-pix botao-autoatendimento secundario"   onClick={() => this.efetuarLiberacaoEmConfianca(this.props.contrato)} style={{marginBottom: 20, marginTop: 20 }}>
                                <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"fa-handshake-angle"}  />Solicitar Liberação em Confiança
                            </a>
                        </span>
                </Modal>
            </>
        )
    }
}
export default withRouter(MenuInicial);