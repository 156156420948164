import './fonts/HKGrotesk-SemiBold.otf';
import './fonts/HKGrotesk-Bold.otf';
import './fonts/HKGrotesk-Regular.otf';

import './App.css';
import React, { Component} from 'react';

import IconeHay from "./images/logo-loading.png";

import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Identificacao from "./pages/Identificacao";
import ContratoSelecionado from "./pages/ContratoSelecionado";
import SelecionarContrato from "./pages/SelecionarContrato";
import PaginaNaoExiste from './pages/PaginaNaoExiste';

import { library as FontAwesomeLib } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Loader from './components/ui/loader';
import UtilService from './service/UtilService';
import ApiService from './service/ApiService';

 
import 'animate.css/animate.css'
import FaleComigo from './pages/FaleComigo';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LiberacaoConfianca from './pages/LiberacaoConfianca';
import MeLigue from './pages/MeLigue';
import MenuInicial from './pages/MenuInicial';



class App extends Component {
    constructor(props) {
        FontAwesomeLib.add(fab, fas);
        super(props);
        this.state = {
            operacaoTotem: {},
        }
    }    
    async componentDidMount() {
        const idOperacaoTotem = UtilService.getUrlParams("idOperacaoTotem") 
        if (idOperacaoTotem){
            const operacaoTotem = await ApiService.validarParametrosTotem(idOperacaoTotem);
            this.setState({ operacaoTotem });
        } else {
            toast.error("Identificação de Totem Ausente",{
                autoClose: false,
                position: "bottom-center",
                theme: "colored"
            });
        }
    }


    aoExpirar (){
        window.location.replace("/?idOperacaoTotem=" + this.state.operacaoTotem.id);
    }

    aoLiberarConfianca(){
        this.state.contrato.situacao = "Conectado/Ativo";
        this.setState({ contrato:  this.state.contrato });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{'HaY Auto-Atendimento'}</title>
                    <meta name="description" content={'HaY Auto-Atendimento'} />
                    <meta name="google" content="notranslate" />
                    <link class="icone-app" rel="apple-touch-icon" href={IconeHay}  />
                    <link class="icone-app" rel="icon" href={IconeHay } />
                </Helmet>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout contrato={this.state.contrato} operacaoTotem={this.state.operacaoTotem} aoExpirar={()=> this.aoExpirar()}  />}>
                            <Route index element={<Home operacaoTotem={this.state.operacaoTotem} onSuccess={(contratos) => this.setState({ contratos })} onSucessUmContrato={(contrato) => this.setState({ contrato })} aoExpirar={()=> this.aoExpirar()}/>} />
                            <Route path="/identificacao/:id" element={<Identificacao operacaoTotem={this.state.operacaoTotem} onSuccess={(contratos) => this.setState({ contratos })} onSucessUmContrato={(contrato) => this.setState({ contrato })} aoExpirar={()=> this.aoExpirar()}/>} />
                            <Route path="/selecionarcontrato/:id" element={<SelecionarContrato contratos={this.state.contratos} operacaoTotem={this.state.operacaoTotem} onSuccess={(contrato) => this.setState({ contrato })} aoExpirar={()=> this.aoExpirar()} />} />
                            <Route path="/contratoselecionado/:id" element={<ContratoSelecionado contrato={this.state.contrato} operacaoTotem={this.state.operacaoTotem} onSuccess={(contrato) => this.setState({ contrato })} aoExpirar={()=> this.aoExpirar()}/>} />
                            <Route path="/menuinicial/:id" element={<MenuInicial contratos={this.state.contratos} contrato={this.state.contrato} operacaoTotem={this.state.operacaoTotem} onSuccess={(contrato) => this.setState({ contrato })} aoExpirar={()=> this.aoExpirar()} /> } />
                            <Route path="/liberacaoconfianca/:id" element={<LiberacaoConfianca contrato={this.state.contrato} operacaoTotem={this.state.operacaoTotem} onSuccess={()=> this.aoLiberarConfianca()}/>} />
                            <Route path="/falecomigo/:id" element={<FaleComigo contrato={this.state.contrato} operacaoTotem={this.state.operacaoTotem} aoExpirar={()=> this.aoExpirar()} />} />
                            <Route path="/meligue/:id" element={<MeLigue contrato={this.state.contrato} operacaoTotem={this.state.operacaoTotem} aoExpirar={()=> this.aoExpirar()} />} />
                            <Route path="*" element={<PaginaNaoExiste />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
                <Loader  operacaoTotem={this.state.operacaoTotem}  />
                <ToastContainer />
            </>
        )
    }
}
export default App;
